var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.appointmentsFreeInfo)?_c('v-alert',{attrs:{"border":"bottom","colored-border":"","type":"info","elevation":"2"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.sanitizeContent(_vm.appointmentsFreeInfo))}})]):_vm._e(),_c('div',[_c('v-list',{attrs:{"three-line":""}},_vm._l((_vm.appointments.dates),function(eventDate,dateIndex){return _c('div',{key:dateIndex},[(dateIndex !== 0)?_c('v-divider'):_vm._e(),_c('v-list-item',[_c('v-list-item-content',[_c('v-row',[_c('v-col',{staticClass:"date-column pb-0 pb-md-3"},[_c('QuokkaDatePicker',{attrs:{"disabled":_vm.disabled,"rules":_vm.getDateFieldIsRequired(dateIndex)
                      ? [_vm.rules.required, _vm.rules.date]
                      : [_vm.rules.date],"label":'Datum' + (_vm.getDateFieldIsRequired(dateIndex) ? ' *' : '')},model:{value:(eventDate.dateObject),callback:function ($$v) {_vm.$set(eventDate, "dateObject", $$v)},expression:"eventDate.dateObject"}})],1),_c('v-spacer')],1)],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"disabled":_vm.disabled || _vm.appointments.dates.length <= 1,"color":"red","title":"Termin löschen"},on:{"click":function($event){return _vm.removeArticleDate(dateIndex)}}},[_vm._v(" mdi-close-circle-outline ")])],1)],1)],1)],1)}),0)],1),_c('v-divider'),(
      _vm.appointments.dates.length <
      _vm.recordingArticle.articleDefinition.publicationDateConfig
        .publication_max_dates
    )?_c('v-btn',{staticClass:"mt-4",attrs:{"disabled":_vm.disabled,"color":"primary","text":""},on:{"click":_vm.addArticleDate}},[_vm._v("Weiterer Termin")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }