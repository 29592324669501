var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex"},[_c('v-card',{attrs:{"flat":"","min-width":"400px","min-height":"300px","color":"transparent"}},[_c('v-card-text',{staticClass:"d-flex justify-center"},[_c('div',[_c('v-card',{staticClass:"mx-5 px-5 py-10",attrs:{"min-width":"800px","min-height":"300px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',[(_vm.datePickerModel)?_c('div',[(_vm.manyPlaces === true)?_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" Verfügbar ")]):(_vm.fewPlaces === true)?_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(" Nur wenige Verfügbar ")]):(_vm.noPlaces === true)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Nicht Verfügbar ")]):(_vm.dateAlreadyBooked === true)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Dieser Tag ist schon ausgewählt ")]):_vm._e()],1):(_vm.noDay === true)?_c('div',[_c('v-alert',{attrs:{"type":"info"}},[_vm._v(" Wählen Sie einen Tag ")])],1):_vm._e(),_c('v-date-picker',{staticClass:"pb-2",attrs:{"first-day-of-week":1,"locale":"de-de","no-title":"","scrollable":""},on:{"change":function($event){return _vm.getAvailabilityForDateAndMedium(
                      _vm.selectedChannel.advert_channel_id,
                      _vm.selectedSlot.advert_slot_id
                    )}},model:{value:(_vm.datePickerModel),callback:function ($$v) {_vm.datePickerModel=$$v},expression:"datePickerModel"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":_vm.bookAble === false},on:{"click":function($event){return _vm.addDay(
                        _vm.selectedChannel.advert_channel_id,
                        _vm.datePickerModel,
                        'add'
                      )}}},[_vm._v(" An diesem Tag buchen ")])],1),(_vm.optionalChannelPlace === true)?_c('div',[_c('v-divider'),(_vm.onlyOptionalChannelPlace === true)?_c('h3',{staticClass:"mt-4"},[_vm._v(" Schalten Sie Ihre "+_vm._s(_vm.FrontendSettings.languageSettings.lbl_advert)+" stattdessen hier: ")]):_c('h3',{staticClass:"mt-4"},[_vm._v(" Schalten Sie Ihre "+_vm._s(_vm.FrontendSettings.languageSettings.lbl_advert)+" auch hier: ")]),_c('v-list',_vm._l((_vm.optionalChannel),function(optChannel,z){return _c('div',{key:z},[(z !== 0)?_c('v-divider'):_vm._e(),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('b',[_vm._v(_vm._s(optChannel.channelData.channelName))])]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(optChannel.slotData.name)+" ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","color":"primary","title":_vm.FrontendSettings.languageSettings.lbl_advert +
                              ' auch hier buchen',"disabled":_vm.optChnBtns[z] === true},on:{"click":function($event){return _vm.addDay(
                                optChannel.channelData.channelId,
                                _vm.datePickerModel,
                                'add',
                                z
                              )}}},[_c('v-icon',[_vm._v("mdi-plus-circle-outline")])],1)],1)],1)],1)}),0)],1):_vm._e()],1)]),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',{attrs:{"cols":"6"}},[(_vm.currentOrderItems.length <= 0)?_c('h3',{staticClass:"red--text text-center"},[_vm._v(" Es sind noch keine Tage ausgewählt. ")]):_c('div',[_c('h3',{staticClass:"text-center"},[_vm._v("Ausgewählte Tage")]),_c('v-list',{attrs:{"max-width":"400px"}},_vm._l((_vm.currentOrderItems),function(orderItem,a){return _c('div',{key:a},[(a !== 0)?_c('v-divider'):_vm._e(),_vm._l((orderItem.booking_dates),function(bookingDate,i){return _c('v-container',{key:i},[(i !== 0)?_c('v-divider'):_vm._e(),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('b',[_vm._v(_vm._s(_vm.formatGermanDate(bookingDate)))])]),_c('v-list-item-subtitle',[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(orderItem.advert_channel_name)+" ")])]),_c('v-list-item-subtitle',[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.selectedSlot.name)+" ")])])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","color":"primary","title":"Datum entfernen"},on:{"click":function($event){return _vm.addDay(
                                orderItem.advert_channel_id,
                                bookingDate,
                                'remove'
                              )}}},[_c('v-icon',[_vm._v("mdi-minus-circle-outline")])],1)],1)],1)],1)})],2)}),0),_c('h4',[_vm._v("Gesamtpreis: "+_vm._s(_vm.totalPrice)+" "+_vm._s(_vm.currencySymbol))])],1)])],1),_c('v-card-actions',{staticClass:"justify-end mt-8"},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.currentOrderItems.length <= 0},on:{"click":function($event){return _vm.whenGoNext()}}},[_vm._v(" Weiter zum Inhalt ")])],1)],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }