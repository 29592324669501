var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"width":"100%"}},[_c('v-card-text',[_c('v-form',{ref:"adsContentForm"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.emptyRequiredTextField)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Bitte alle Textfelder ausfüllen. ")]):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.enabledContentDefinition),function(cdField,d){return _c('div',{key:d},[_c('v-row',[_c('v-col',[(cdField.fieldType === 'text')?_c('v-text-field',{staticClass:"contentData ma-2",attrs:{"rules":[
                    _vm.rules[cdField.fieldName],
                    ...(cdField.required === 'Y' ? [_vm.rules.required] : []),
                  ],"name":cdField.fieldName,"counter":"","label":cdField.fieldLabel +
                    (cdField.required === 'Y' ? ' *' : ''),"maxlength":cdField.maxLength},model:{value:(_vm.contentFieldValues[cdField.fieldName]),callback:function ($$v) {_vm.$set(_vm.contentFieldValues, cdField.fieldName, $$v)},expression:"contentFieldValues[cdField.fieldName]"}}):_c('v-textarea',{staticClass:"contentData ma-2",attrs:{"counter":"","rules":[
                    _vm.rules[cdField.fieldName],
                    ...(cdField.required === 'Y' ? [_vm.rules.required] : []),
                  ],"name":cdField.fieldName,"label":cdField.fieldLabel +
                    (cdField.required === 'Y' ? ' *' : ''),"maxlength":cdField.maxLength},model:{value:(_vm.contentFieldValues[cdField.fieldName]),callback:function ($$v) {_vm.$set(_vm.contentFieldValues, cdField.fieldName, $$v)},expression:"contentFieldValues[cdField.fieldName]"}})],1)],1)],1)}),0)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.noRequiredMedium)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Kein Medium gewählt oder hochgeladen. ")]):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticStyle:{"max-width":"900px"}},[(
                _vm.availableMediaFromEvent && _vm.availableMediaFromEvent.length > 0
              )?_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v("Medien aus der Veranstaltung übernehmen")])],1),_c('v-row',_vm._l((_vm.availableMediaFromEvent),function(availableMedia,amKey){return _c('v-col',{key:amKey,attrs:{"cols":"6"}},[_c('v-card',{staticClass:"event-img overflow-hidden",class:availableMedia.selected ? 'selected' : '',attrs:{"outlined":availableMedia.selected,"elevation":availableMedia.selected ? 8 : 0},on:{"click":function($event){return _vm.selectMedia(amKey)}}},[_c('v-img',{attrs:{"src":availableMedia.base64}},[_c('v-fade-transition',[(availableMedia.selected)?_c('v-overlay',{staticClass:"overlay-settings d-flex align-start justify-end",attrs:{"absolute":"","color":"transparent"}},[_c('v-icon',{staticClass:"success-icon ma-1",attrs:{"color":"success lighten1"}},[_vm._v(" mdi-check-circle-outline ")])],1):_vm._e()],1)],1)],1)],1)}),1)],1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.availableMediaFromEvent && _vm.availableMediaFromEvent.length > 0
                  ? 6
                  : 12}},[(_vm.selectedSlot.image_amount > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v("Medien hochladen")])],1):_vm._e(),(_vm.selectedSlot.image_amount > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.advertContentMediaUploaderData)?_c('MediaUploader',{attrs:{"media-uploader-data":_vm.advertContentMediaUploaderData,"max-files":_vm.selectedSlot.image_amount,"type":"ads"},on:{"file-uploaded":_vm.onFileUploaded}}):_vm._e()],1)],1):_vm._e()],1)],1)],1)],1),_c('v-row',_vm._l((_vm.availableMedia),function(media,i){return _c('v-expansion-panels',{key:i,staticClass:"full-width"},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"expansion-panel-header-color mb-3",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"expansion-panel-header-color"},[_vm._v(" mdi-chevron-down ")])]},proxy:true}],null,true)},[(media.base64)?_c('v-img',{staticClass:"mr-4",attrs:{"src":media.base64,"max-height":"100px","max-width":"100px"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.getMediaTitle(media))+" ")],1),_c('v-expansion-panel-content',[(_vm.mediaConfigData['additionalImageInformationFields'])?_c('v-row',[_c('v-col',{staticClass:"mb-0",staticStyle:{"padding-left":"10%","padding-right":"10%"},attrs:{"cols":"12"}},[(
                      _vm.mediaConfigData['additionalImageInformationFields'][
                        'title'
                      ] &&
                      _vm.mediaConfigData['additionalImageInformationFields'][
                        'title'
                      ]['active'] === 'Y'
                    )?_c('v-text-field',{attrs:{"name":i + '_title',"label":_vm.mediaConfigData['additionalImageInformationFields'][
                        'title'
                      ]['label'] +
                      (_vm.mediaConfigData['additionalImageInformationFields'][
                        'title'
                      ]['require'] === 'Y'
                        ? ' *'
                        : ''),"maxlength":_vm.mediaConfigData['additionalImageInformationFields'][
                        'title'
                      ]['maxlength'],"rules":_vm.mediaConfigData['additionalImageInformationFields'][
                        'title'
                      ]['require'] === 'Y'
                        ? [_vm.rules.requiredMediaText]
                        : []},model:{value:(media['title']),callback:function ($$v) {_vm.$set(media, 'title', $$v)},expression:"media['title']"}}):_vm._e()],1),_c('v-col',{staticClass:"mb-0",staticStyle:{"padding-left":"10%","padding-right":"10%"},attrs:{"cols":"12"}},[(
                      _vm.mediaConfigData['additionalImageInformationFields'][
                        'desc'
                      ] &&
                      _vm.mediaConfigData['additionalImageInformationFields'][
                        'desc'
                      ]['active'] === 'Y'
                    )?_c('v-text-field',{attrs:{"name":i + '_desc',"label":_vm.mediaConfigData['additionalImageInformationFields'][
                        'desc'
                      ]['label'] +
                      (_vm.mediaConfigData['additionalImageInformationFields'][
                        'desc'
                      ]['require'] === 'Y'
                        ? ' *'
                        : ''),"maxlength":_vm.mediaConfigData['additionalImageInformationFields'][
                        'desc'
                      ]['maxlength'],"rules":_vm.mediaConfigData['additionalImageInformationFields'][
                        'desc'
                      ]['require'] === 'Y'
                        ? [_vm.rules.requiredMediaText]
                        : []},model:{value:(media['desc']),callback:function ($$v) {_vm.$set(media, 'desc', $$v)},expression:"media['desc']"}}):_vm._e()],1),_c('v-col',{staticClass:"mb-0",staticStyle:{"padding-left":"10%","padding-right":"10%"},attrs:{"cols":"12"}},[(
                      _vm.mediaConfigData['additionalImageInformationFields'][
                        'agency'
                      ] &&
                      _vm.mediaConfigData['additionalImageInformationFields'][
                        'agency'
                      ]['active'] === 'Y'
                    )?_c('v-text-field',{attrs:{"name":i + '_agency',"label":_vm.mediaConfigData['additionalImageInformationFields'][
                        'agency'
                      ]['label'] +
                      (_vm.mediaConfigData['additionalImageInformationFields'][
                        'agency'
                      ]['require'] === 'Y'
                        ? ' *'
                        : ''),"maxlength":_vm.mediaConfigData['additionalImageInformationFields'][
                        'agency'
                      ]['maxlength'],"rules":_vm.mediaConfigData['additionalImageInformationFields'][
                        'agency'
                      ]['require'] === 'Y'
                        ? [_vm.rules.requiredMediaText]
                        : []},model:{value:(media['agency']),callback:function ($$v) {_vm.$set(media, 'agency', $$v)},expression:"media['agency']"}}):_vm._e()],1),_c('v-col',{staticClass:"mb-0",staticStyle:{"padding-left":"10%","padding-right":"10%"},attrs:{"cols":"12"}},[(
                      _vm.mediaConfigData['additionalImageInformationFields'][
                        'author'
                      ] &&
                      _vm.mediaConfigData['additionalImageInformationFields'][
                        'author'
                      ]['active'] === 'Y'
                    )?_c('v-text-field',{attrs:{"name":i + '_author',"label":_vm.mediaConfigData['additionalImageInformationFields'][
                        'author'
                      ]['label'] +
                      (_vm.mediaConfigData['additionalImageInformationFields'][
                        'author'
                      ]['require'] === 'Y'
                        ? ' *'
                        : ''),"maxlength":_vm.mediaConfigData['additionalImageInformationFields'][
                        'author'
                      ]['maxlength'],"rules":_vm.mediaConfigData['additionalImageInformationFields'][
                        'author'
                      ]['require'] === 'Y'
                        ? [_vm.rules.requiredMediaText]
                        : []},model:{value:(media['author']),callback:function ($$v) {_vm.$set(media, 'author', $$v)},expression:"media['author']"}}):_vm._e()],1),_c('v-col',{staticClass:"mb-0",staticStyle:{"padding-left":"10%","padding-right":"10%"},attrs:{"cols":"12"}},[(
                      _vm.mediaConfigData['additionalImageInformationFields'][
                        'location'
                      ] &&
                      _vm.mediaConfigData['additionalImageInformationFields'][
                        'location'
                      ]['active'] === 'Y'
                    )?_c('v-text-field',{attrs:{"name":i + '_location',"label":_vm.mediaConfigData['additionalImageInformationFields'][
                        'location'
                      ]['label'] +
                      (_vm.mediaConfigData['additionalImageInformationFields'][
                        'location'
                      ]['require'] === 'Y'
                        ? ' *'
                        : ''),"maxlength":_vm.mediaConfigData['additionalImageInformationFields'][
                        'location'
                      ]['maxlength'],"rules":_vm.mediaConfigData['additionalImageInformationFields'][
                        'location'
                      ]['require'] === 'Y'
                        ? [_vm.rules.requiredMediaText]
                        : []},model:{value:(media['location']),callback:function ($$v) {_vm.$set(media, 'location', $$v)},expression:"media['location']"}}):_vm._e()],1),_c('v-col',{staticClass:"mb-0",staticStyle:{"padding-left":"10%","padding-right":"10%"},attrs:{"cols":"12"}},[(
                      _vm.mediaConfigData['additionalImageInformationFields'][
                        'copyright'
                      ] &&
                      _vm.mediaConfigData['additionalImageInformationFields'][
                        'copyright'
                      ]['active'] === 'Y'
                    )?_c('v-text-field',{attrs:{"name":i + '_copyright',"label":_vm.mediaConfigData['additionalImageInformationFields'][
                        'copyright'
                      ]['label'] +
                      (_vm.mediaConfigData['additionalImageInformationFields'][
                        'copyright'
                      ]['require'] === 'Y'
                        ? ' *'
                        : ''),"maxlength":_vm.mediaConfigData['additionalImageInformationFields'][
                        'copyright'
                      ]['maxlength'],"rules":_vm.mediaConfigData['additionalImageInformationFields'][
                        'copyright'
                      ]['require'] === 'Y'
                        ? [_vm.rules.requiredMediaText]
                        : []},model:{value:(media['copyright']),callback:function ($$v) {_vm.$set(media, 'copyright', $$v)},expression:"media['copyright']"}}):_vm._e()],1)],1):_vm._e()],1)],1)],1)}),1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","disabled":_vm.noRequiredMedium || _vm.loading},on:{"click":_vm.setAdvertContent}},[_vm._v(" Weiter zur Rechnungsadresse ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }