var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"width":"100%"}},[(_vm.altInvoiceAddressSettings && _vm.loading === false)?_c('v-card-text',{attrs:{"width":"100%"}},[_c('v-row',[_c('v-col',[_c('v-form',{ref:"personBillingAddressForm"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.emptyRequiredPersonBillingData)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Bitte füllen Sie alle mit '*' gekennzeichneten Pflichtfelder zu Ihrer Person aus. ")]):_vm._e()],1)],1),(
              _vm.altInvoiceAddressSettings.allow.salutation &&
              _vm.altInvoiceAddressSettings.allow.salutation === 'Y'
            )?_c('v-row',[_c('v-col',[(_vm.altInvoiceAddressSettings.allow.salutation === 'Y')?_c('v-select',{attrs:{"label":_vm.altInvoiceAddressSettings.label.salutation +
                  (_vm.altInvoiceAddressSettings.require.salutation === 'Y'
                    ? ' *'
                    : ''),"rules":[
                  _vm.rules['salutation'],
                  ...(_vm.altInvoiceAddressSettings.require.salutation === 'Y'
                    ? [_vm.rules.required]
                    : []),
                ],"disabled":_vm.loading,"items":_vm.salutationsData},model:{value:(_vm.personOrderAddress.salutation),callback:function ($$v) {_vm.$set(_vm.personOrderAddress, "salutation", $$v)},expression:"personOrderAddress.salutation"}}):_vm._e()],1)],1):_vm._e(),(
              _vm.altInvoiceAddressSettings.allow.first_name === 'Y' ||
              _vm.altInvoiceAddressSettings.allow.last_name === 'Y'
            )?_c('v-row',[(_vm.altInvoiceAddressSettings.allow.first_name === 'Y')?_c('v-col',[(_vm.altInvoiceAddressSettings.allow.first_name === 'Y')?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.first_name +
                  (_vm.altInvoiceAddressSettings.require.first_name === 'Y'
                    ? ' *'
                    : ''),"rules":[
                  _vm.rules['firstName'],
                  ...(_vm.altInvoiceAddressSettings.require.first_name === 'Y'
                    ? [_vm.rules.required]
                    : []),
                ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.personOrderAddress.first_name),callback:function ($$v) {_vm.$set(_vm.personOrderAddress, "first_name", $$v)},expression:"personOrderAddress.first_name"}}):_vm._e()],1):_vm._e(),(_vm.altInvoiceAddressSettings.allow.last_name === 'Y')?_c('v-col',[(_vm.altInvoiceAddressSettings.allow.last_name === 'Y')?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.last_name +
                  (_vm.altInvoiceAddressSettings.require.last_name === 'Y'
                    ? ' *'
                    : ''),"rules":[
                  _vm.rules['lastName'],
                  ...(_vm.altInvoiceAddressSettings.require.last_name === 'Y'
                    ? [_vm.rules.required]
                    : []),
                ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.personOrderAddress.last_name),callback:function ($$v) {_vm.$set(_vm.personOrderAddress, "last_name", $$v)},expression:"personOrderAddress.last_name"}}):_vm._e()],1):_vm._e()],1):_vm._e(),(_vm.altInvoiceAddressSettings.allow.phone === 'Y')?_c('v-row',[_c('v-col',[(_vm.altInvoiceAddressSettings.allow.phone === 'Y')?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.phone +
                  (_vm.altInvoiceAddressSettings.require.phone === 'Y'
                    ? ' *'
                    : ''),"rules":[
                  _vm.rules['phone'],
                  ...(_vm.altInvoiceAddressSettings.require.phone === 'Y'
                    ? [_vm.rules.required]
                    : []),
                ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.personOrderAddress.phone),callback:function ($$v) {_vm.$set(_vm.personOrderAddress, "phone", $$v)},expression:"personOrderAddress.phone"}}):_vm._e()],1)],1):_vm._e(),(_vm.altInvoiceAddressSettings.allow.mobile === 'Y')?_c('v-row',[_c('v-col',[(_vm.altInvoiceAddressSettings.allow.mobile === 'Y')?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.mobile +
                  (_vm.altInvoiceAddressSettings.require.mobile === 'Y'
                    ? ' *'
                    : ''),"rules":[
                  _vm.rules['mobile'],
                  ...(_vm.altInvoiceAddressSettings.require.mobile === 'Y'
                    ? [_vm.rules.required]
                    : []),
                ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.personOrderAddress.mobile),callback:function ($$v) {_vm.$set(_vm.personOrderAddress, "mobile", $$v)},expression:"personOrderAddress.mobile"}}):_vm._e()],1)],1):_vm._e(),(_vm.altInvoiceAddressSettings.allow.email === 'Y')?_c('v-row',[_c('v-col',[(_vm.altInvoiceAddressSettings.allow.email === 'Y')?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.email +
                  (_vm.altInvoiceAddressSettings.require.email === 'Y'
                    ? ' *'
                    : ''),"rules":[
                  _vm.rules['email'],
                  ...(_vm.altInvoiceAddressSettings.require.email === 'Y'
                    ? [_vm.rules.required]
                    : []),
                ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.personOrderAddress.email),callback:function ($$v) {_vm.$set(_vm.personOrderAddress, "email", $$v)},expression:"personOrderAddress.email"}}):_vm._e()],1)],1):_vm._e(),(_vm.altInvoiceAddressSettings.allow.organization === 'Y')?_c('v-row',[_c('v-col',[(_vm.altInvoiceAddressSettings.allow.organization === 'Y')?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.organization +
                  (_vm.altInvoiceAddressSettings.require.organization === 'Y'
                    ? ' *'
                    : ''),"rules":[
                  _vm.rules['organization'],
                  ...(_vm.altInvoiceAddressSettings.require.organization === 'Y'
                    ? [_vm.rules.required]
                    : []),
                ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.personOrderAddress.organization),callback:function ($$v) {_vm.$set(_vm.personOrderAddress, "organization", $$v)},expression:"personOrderAddress.organization"}}):_vm._e()],1)],1):_vm._e(),(_vm.altInvoiceAddressSettings.allow.street === 'Y')?_c('v-row',[_c('v-col',[(_vm.altInvoiceAddressSettings.allow.street === 'Y')?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.street +
                  (_vm.altInvoiceAddressSettings.require.street === 'Y'
                    ? ' *'
                    : ''),"rules":[
                  _vm.rules['street'],
                  ...(_vm.altInvoiceAddressSettings.require.street === 'Y'
                    ? [_vm.rules.required]
                    : []),
                ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.personOrderAddress.street),callback:function ($$v) {_vm.$set(_vm.personOrderAddress, "street", $$v)},expression:"personOrderAddress.street"}}):_vm._e()],1)],1):_vm._e(),(
              _vm.altInvoiceAddressSettings.allow.zipcode === 'Y' ||
              _vm.altInvoiceAddressSettings.allow.community === 'Y'
            )?_c('v-row',[(_vm.altInvoiceAddressSettings.allow.zipcode === 'Y')?_c('v-col',[(_vm.altInvoiceAddressSettings.allow.zipcode === 'Y')?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.zipcode +
                  (_vm.altInvoiceAddressSettings.require.zipcode === 'Y'
                    ? ' *'
                    : ''),"rules":[
                  _vm.rules['zipcode'],
                  ...(_vm.altInvoiceAddressSettings.require.zipcode === 'Y'
                    ? [_vm.rules.required]
                    : []),
                ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.personOrderAddress.zipcode),callback:function ($$v) {_vm.$set(_vm.personOrderAddress, "zipcode", $$v)},expression:"personOrderAddress.zipcode"}}):_vm._e()],1):_vm._e(),(_vm.altInvoiceAddressSettings.allow.community === 'Y')?_c('v-col',[(_vm.altInvoiceAddressSettings.allow.community === 'Y')?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.community +
                  (_vm.altInvoiceAddressSettings.require.community === 'Y'
                    ? ' *'
                    : ''),"rules":[
                  _vm.rules['community'],
                  ...(_vm.altInvoiceAddressSettings.require.community === 'Y'
                    ? [_vm.rules.required]
                    : []),
                ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.personOrderAddress.community),callback:function ($$v) {_vm.$set(_vm.personOrderAddress, "community", $$v)},expression:"personOrderAddress.community"}}):_vm._e()],1):_vm._e()],1):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',[(_vm.altInvoiceAddressAllow === 'Y')?_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Abweichende Rechnungsadresse erfassen ")]),_c('v-expansion-panel-content',[_c('v-form',{ref:"diffBillingAddressForm",model:{value:(_vm.diffBillingAddressFormValid),callback:function ($$v) {_vm.diffBillingAddressFormValid=$$v},expression:"diffBillingAddressFormValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.emptyRequiredTextField)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Bitte füllen Sie alle Pflichtfelder aus. ")]):_vm._e()],1)],1),(_vm.altInvoiceAddressSettings.allow.salutation === 'Y')?_c('v-row',[_c('v-col',[(
                        _vm.altInvoiceAddressSettings.allow.salutation === 'Y'
                      )?_c('v-select',{attrs:{"label":_vm.altInvoiceAddressSettings.label.salutation +
                        (_vm.altInvoiceAddressSettings.require.salutation === 'Y'
                          ? ' *'
                          : ''),"rules":[
                        _vm.rules['salutation'],
                        ...(_vm.altInvoiceAddressSettings.require.salutation ===
                        'Y'
                          ? [_vm.rules.required]
                          : []),
                      ],"disabled":_vm.loading,"items":_vm.salutationsData},model:{value:(_vm.differentBillingAddressFieldValues.salutation),callback:function ($$v) {_vm.$set(_vm.differentBillingAddressFieldValues, "salutation", $$v)},expression:"differentBillingAddressFieldValues.salutation"}}):_vm._e()],1)],1):_vm._e(),(
                    _vm.altInvoiceAddressSettings.allow.first_name === 'Y' ||
                    _vm.altInvoiceAddressSettings.allow.last_name === 'Y'
                  )?_c('v-row',[(_vm.altInvoiceAddressSettings.allow.first_name === 'Y')?_c('v-col',[(
                        _vm.altInvoiceAddressSettings.allow.first_name === 'Y'
                      )?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.first_name +
                        (_vm.altInvoiceAddressSettings.require.first_name === 'Y'
                          ? ' *'
                          : ''),"rules":[
                        _vm.rules['firstName'],
                        ...(_vm.altInvoiceAddressSettings.require.first_name ===
                        'Y'
                          ? [_vm.rules.required]
                          : []),
                      ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.differentBillingAddressFieldValues.first_name),callback:function ($$v) {_vm.$set(_vm.differentBillingAddressFieldValues, "first_name", $$v)},expression:"differentBillingAddressFieldValues.first_name"}}):_vm._e()],1):_vm._e(),(_vm.altInvoiceAddressSettings.allow.last_name === 'Y')?_c('v-col',[(_vm.altInvoiceAddressSettings.allow.last_name === 'Y')?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.last_name +
                        (_vm.altInvoiceAddressSettings.require.last_name === 'Y'
                          ? ' *'
                          : ''),"rules":[
                        _vm.rules['lastName'],
                        ...(_vm.altInvoiceAddressSettings.require.last_name ===
                        'Y'
                          ? [_vm.rules.required]
                          : []),
                      ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.differentBillingAddressFieldValues.last_name),callback:function ($$v) {_vm.$set(_vm.differentBillingAddressFieldValues, "last_name", $$v)},expression:"differentBillingAddressFieldValues.last_name"}}):_vm._e()],1):_vm._e()],1):_vm._e(),(_vm.altInvoiceAddressSettings.allow.phone === 'Y')?_c('v-row',[_c('v-col',[(_vm.altInvoiceAddressSettings.allow.phone === 'Y')?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.phone +
                        (_vm.altInvoiceAddressSettings.require.phone === 'Y'
                          ? ' *'
                          : ''),"rules":[
                        _vm.rules['phone'],
                        ...(_vm.altInvoiceAddressSettings.require.phone === 'Y'
                          ? [_vm.rules.required]
                          : []),
                      ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.differentBillingAddressFieldValues.phone),callback:function ($$v) {_vm.$set(_vm.differentBillingAddressFieldValues, "phone", $$v)},expression:"differentBillingAddressFieldValues.phone"}}):_vm._e()],1)],1):_vm._e(),(_vm.altInvoiceAddressSettings.allow.mobile === 'Y')?_c('v-row',[_c('v-col',[(_vm.altInvoiceAddressSettings.allow.mobile === 'Y')?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.mobile +
                        (_vm.altInvoiceAddressSettings.require.mobile === 'Y'
                          ? ' *'
                          : ''),"rules":[
                        _vm.rules['mobile'],
                        ...(_vm.altInvoiceAddressSettings.require.mobile === 'Y'
                          ? [_vm.rules.required]
                          : []),
                      ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.differentBillingAddressFieldValues.mobile),callback:function ($$v) {_vm.$set(_vm.differentBillingAddressFieldValues, "mobile", $$v)},expression:"differentBillingAddressFieldValues.mobile"}}):_vm._e()],1)],1):_vm._e(),(_vm.altInvoiceAddressSettings.allow.email === 'Y')?_c('v-row',[_c('v-col',[(_vm.altInvoiceAddressSettings.allow.email === 'Y')?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.email +
                        (_vm.altInvoiceAddressSettings.require.email === 'Y'
                          ? ' *'
                          : ''),"rules":[
                        _vm.rules['email'],
                        ...(_vm.altInvoiceAddressSettings.require.email === 'Y'
                          ? [_vm.rules.required]
                          : []),
                      ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.differentBillingAddressFieldValues.email),callback:function ($$v) {_vm.$set(_vm.differentBillingAddressFieldValues, "email", $$v)},expression:"differentBillingAddressFieldValues.email"}}):_vm._e()],1)],1):_vm._e(),(_vm.altInvoiceAddressSettings.allow.organization === 'Y')?_c('v-row',[_c('v-col',[(
                        _vm.altInvoiceAddressSettings.allow.organization === 'Y'
                      )?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.organization +
                        (_vm.altInvoiceAddressSettings.require.organization ===
                        'Y'
                          ? ' *'
                          : ''),"rules":[
                        _vm.rules['organization'],
                        ...(_vm.altInvoiceAddressSettings.require.organization ===
                        'Y'
                          ? [_vm.rules.required]
                          : []),
                      ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(
                        _vm.differentBillingAddressFieldValues.organization
                      ),callback:function ($$v) {_vm.$set(_vm.differentBillingAddressFieldValues, "organization", $$v)},expression:"\n                        differentBillingAddressFieldValues.organization\n                      "}}):_vm._e()],1)],1):_vm._e(),(_vm.altInvoiceAddressSettings.allow.department === 'Y')?_c('v-row',[_c('v-col',[(
                        _vm.altInvoiceAddressSettings.allow.department === 'Y'
                      )?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.department +
                        (_vm.altInvoiceAddressSettings.require.department === 'Y'
                          ? ' *'
                          : ''),"rules":[
                        _vm.rules['department'],
                        ...(_vm.altInvoiceAddressSettings.require.department ===
                        'Y'
                          ? [_vm.rules.required]
                          : []),
                      ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.differentBillingAddressFieldValues.department),callback:function ($$v) {_vm.$set(_vm.differentBillingAddressFieldValues, "department", $$v)},expression:"differentBillingAddressFieldValues.department"}}):_vm._e()],1)],1):_vm._e(),(_vm.altInvoiceAddressSettings.allow.street === 'Y')?_c('v-row',[_c('v-col',[(_vm.altInvoiceAddressSettings.allow.street === 'Y')?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.street +
                        (_vm.altInvoiceAddressSettings.require.street === 'Y'
                          ? ' *'
                          : ''),"rules":[
                        _vm.rules['street'],
                        ...(_vm.altInvoiceAddressSettings.require.street === 'Y'
                          ? [_vm.rules.required]
                          : []),
                      ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.differentBillingAddressFieldValues.street),callback:function ($$v) {_vm.$set(_vm.differentBillingAddressFieldValues, "street", $$v)},expression:"differentBillingAddressFieldValues.street"}}):_vm._e()],1)],1):_vm._e(),(
                    _vm.altInvoiceAddressSettings.allow.zipcode === 'Y' ||
                    _vm.altInvoiceAddressSettings.allow.community === 'Y'
                  )?_c('v-row',[(_vm.altInvoiceAddressSettings.allow.zipcode === 'Y')?_c('v-col',[(_vm.altInvoiceAddressSettings.allow.zipcode === 'Y')?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.zipcode +
                        (_vm.altInvoiceAddressSettings.require.zipcode === 'Y'
                          ? ' *'
                          : ''),"rules":[
                        _vm.rules['zipcode'],
                        ...(_vm.altInvoiceAddressSettings.require.zipcode === 'Y'
                          ? [_vm.rules.required]
                          : []),
                      ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.differentBillingAddressFieldValues.zipcode),callback:function ($$v) {_vm.$set(_vm.differentBillingAddressFieldValues, "zipcode", $$v)},expression:"differentBillingAddressFieldValues.zipcode"}}):_vm._e()],1):_vm._e(),(_vm.altInvoiceAddressSettings.allow.community === 'Y')?_c('v-col',[(_vm.altInvoiceAddressSettings.allow.community === 'Y')?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.community +
                        (_vm.altInvoiceAddressSettings.require.community === 'Y'
                          ? ' *'
                          : ''),"rules":[
                        _vm.rules['community'],
                        ...(_vm.altInvoiceAddressSettings.require.community ===
                        'Y'
                          ? [_vm.rules.required]
                          : []),
                      ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.differentBillingAddressFieldValues.community),callback:function ($$v) {_vm.$set(_vm.differentBillingAddressFieldValues, "community", $$v)},expression:"differentBillingAddressFieldValues.community"}}):_vm._e()],1):_vm._e()],1):_vm._e(),(_vm.altInvoiceAddressSettings.allow.country === 'Y')?_c('v-row',[_c('v-col',[(_vm.altInvoiceAddressSettings.allow.country === 'Y')?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.country +
                        (_vm.altInvoiceAddressSettings.require.country === 'Y'
                          ? ' *'
                          : ''),"rules":[
                        _vm.rules['country'],
                        ...(_vm.altInvoiceAddressSettings.require.country === 'Y'
                          ? [_vm.rules.required]
                          : []),
                      ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.differentBillingAddressFieldValues.country),callback:function ($$v) {_vm.$set(_vm.differentBillingAddressFieldValues, "country", $$v)},expression:"differentBillingAddressFieldValues.country"}}):_vm._e()],1)],1):_vm._e(),(_vm.altInvoiceAddressSettings.allow.attention === 'Y')?_c('v-row',[_c('v-col',[(_vm.altInvoiceAddressSettings.allow.attention === 'Y')?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.attention +
                        (_vm.altInvoiceAddressSettings.require.attention === 'Y'
                          ? ' *'
                          : ''),"rules":[
                        _vm.rules['attention'],
                        ...(_vm.altInvoiceAddressSettings.require.attention ===
                        'Y'
                          ? [_vm.rules.required]
                          : []),
                      ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.differentBillingAddressFieldValues.attention),callback:function ($$v) {_vm.$set(_vm.differentBillingAddressFieldValues, "attention", $$v)},expression:"differentBillingAddressFieldValues.attention"}}):_vm._e()],1)],1):_vm._e(),(_vm.altInvoiceAddressSettings.allow.pobox === 'Y')?_c('v-row',[_c('v-col',[(_vm.altInvoiceAddressSettings.allow.pobox === 'Y')?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.pobox +
                        (_vm.altInvoiceAddressSettings.require.pobox === 'Y'
                          ? ' *'
                          : ''),"rules":[
                        _vm.rules['pobox'],
                        ...(_vm.altInvoiceAddressSettings.require.pobox === 'Y'
                          ? [_vm.rules.required]
                          : []),
                      ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.differentBillingAddressFieldValues.pobox),callback:function ($$v) {_vm.$set(_vm.differentBillingAddressFieldValues, "pobox", $$v)},expression:"differentBillingAddressFieldValues.pobox"}}):_vm._e()],1)],1):_vm._e(),(
                    _vm.altInvoiceAddressSettings.allow.pozip === 'Y' ||
                    _vm.altInvoiceAddressSettings.allow.pocommunity === 'Y'
                  )?_c('v-row',[(_vm.altInvoiceAddressSettings.allow.pozip === 'Y')?_c('v-col',[(_vm.altInvoiceAddressSettings.allow.pozip === 'Y')?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.pozip +
                        (_vm.altInvoiceAddressSettings.require.pozip === 'Y'
                          ? ' *'
                          : ''),"rules":[
                        _vm.rules['pozip'],
                        ...(_vm.altInvoiceAddressSettings.require.pozip === 'Y'
                          ? [_vm.rules.required]
                          : []),
                      ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.differentBillingAddressFieldValues.pozip),callback:function ($$v) {_vm.$set(_vm.differentBillingAddressFieldValues, "pozip", $$v)},expression:"differentBillingAddressFieldValues.pozip"}}):_vm._e()],1):_vm._e(),(_vm.altInvoiceAddressSettings.allow.pocommunity === 'Y')?_c('v-col',[(
                        _vm.altInvoiceAddressSettings.allow.pocommunity === 'Y'
                      )?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.pocommunity +
                        (_vm.altInvoiceAddressSettings.require.pocommunity === 'Y'
                          ? ' *'
                          : ''),"rules":[
                        _vm.rules['pocommunity'],
                        ...(_vm.altInvoiceAddressSettings.require.pocommunity ===
                        'Y'
                          ? [_vm.rules.required]
                          : []),
                      ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.differentBillingAddressFieldValues.pocommunity),callback:function ($$v) {_vm.$set(_vm.differentBillingAddressFieldValues, "pocommunity", $$v)},expression:"differentBillingAddressFieldValues.pocommunity"}}):_vm._e()],1):_vm._e()],1):_vm._e(),(_vm.altInvoiceAddressSettings.allow.vat_id === 'Y')?_c('v-row',[_c('v-col',[(_vm.altInvoiceAddressSettings.allow.vat_id === 'Y')?_c('v-text-field',{attrs:{"label":_vm.altInvoiceAddressSettings.label.vat_id +
                        (_vm.altInvoiceAddressSettings.require.vat_id === 'Y'
                          ? ' *'
                          : ''),"rules":[
                        _vm.rules['vat_id'],
                        ...(_vm.altInvoiceAddressSettings.require.vat_id === 'Y'
                          ? [_vm.rules.required]
                          : []),
                      ],"disabled":_vm.loading,"hide-details":"auto","clearable":""},model:{value:(_vm.differentBillingAddressFieldValues.vat_id),callback:function ($$v) {_vm.$set(_vm.differentBillingAddressFieldValues, "vat_id", $$v)},expression:"differentBillingAddressFieldValues.vat_id"}}):_vm._e()],1)],1):_vm._e()],1)],1)],1)],1):_vm._e()],1)],1)],1):_vm._e(),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.goToOverview()}}},[_vm._v(" Weiter zur Übersicht ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }